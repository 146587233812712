<template>
  <div id="parent">
    <b-overlay
      :show="show"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <forexTabs />
      <b-card>
        <b-row
          align-v="center"
          class="mb-0 pb-1"
          style="border-bottom: 1px solid #ddd"
        >
          <b-col
            class="text-center"
            style="cursor: pointer"
            cols="auto"
            @click="$router.go(-1)"
          >
            <b-col cols="12">
              {{ $t("back") }}
            </b-col>
            <b-col cols="12">
              <feather-icon
                v-if="$i18n.isRTL"
                icon="ArrowRightIcon"
                size="14"
              />
              <feather-icon
                v-else
                icon="ArrowLeftIcon"
                size="14"
              />
            </b-col>
          </b-col>
        </b-row>
        <b-row>
          <b-card-body>
            <validation-observer>
              <b-form class="m-2 mt-0">
                <b-row>
                  <b-col sm="4">

                    <b-form-group
                      :label="$t('accounts')"
                      class="mb-1"
                      label-for="acoounts"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Acoounts"
                        vid="acoounts"
                        rules="required"
                      >
                        <v-select
                          id="acoounts"
                          v-model="accountSelected"
                          :clearable="false"
                          :options="accounts"
                          label="account"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      :label="$t('type')"
                      class="mb-1"
                      label-for="type"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Type"
                        vid="type"
                        rules="required"
                      >
                        <v-select
                          id="type"
                          v-model="typeSelected"
                          :clearable="false"
                          :options="type"
                          label="title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col sm="4">
                    <b-button
                      variant="primary"
                      class="mt-2"
                      @click="change"
                    >
                      <span v-if="!pending">
                        {{ $t('change') }}</span>
                      <b-spinner
                        v-if="pending"
                        variant="light"
                        type="grow"
                        small
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-row>
      </b-card>
      <b-card
        v-if="passwordInfo"
        id="successCard"
        class="mt-4"
      >
        <b-alert
          show
          variant="success"
          class="p-1"
        >
          <p>{{ $t('forex.password-successful-msg') }}</p>
        </b-alert>
        <b-form class="mt-2">
          <b-row>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                :label="$t('new_Password')"
                label-for="city"
              >
                <validation-provider
                  #default="{ errors }"
                  name="account number"
                  vid="city"
                  rules="required"
                >
                  <b-form-input
                    v-model="password"
                    :placeholder="$t('new_Password')"
                    name="city"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>

        </b-form>
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BForm,
  BFormInput,
  BAlert,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import MetatraderService from '@/modules/forex/service/apis'
import forexTabs from './components/forexTabs.vue'

const accountCards = new MetatraderService()

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
    vSelect,
    BForm,
    BFormInput,
    BAlert,
    forexTabs,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      accounts: [],
      pending: false,
      passwordInfo: false,
      password: '',
      typeSelected: { title: 'main' },
      accountSelected: '',
      required,
      show: false,
      type: [
        { title: 'main' },
        { title: 'investor' },
      ],
      platform: '',
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.show = true
      await accountCards.getAccounts().then(res => {
        this.accounts = res.data.results
        if (this.$route.params.id) {
          this.accountSelected = this.$route.params.id
          const [platform] = res.data.results.map(item => item.platform)
          this.platform = platform
        }
        this.show = false
      }).catch(err => {
        console.log(err)
      })
    },
    async change() {
      this.pending = true
      const data = { platform: this.platform, type: this.typeSelected.title, account: this.$route.params.id }
      await accountCards.saveChangePassword(data).then(res => {
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 4000,
          showConfirmButton: false,
        })
        this.pending = false
        this.passwordInfo = true
        this.password = res.data.results.password
      }).catch(err => {
        console.log(err)
        this.pending = false
      }).finally(() => {
        const elementOne = document.getElementById('parent')
        const elementTwo = document.getElementById('successCard')
        window.scroll({
          top: elementOne.scrollHeight - elementTwo.scrollHeight,
          behavior: 'smooth',
        })
      })
    },
  },
}
</script>
